import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { BuyedBooksStore, BuyedBooksState } from './buyed-books.store.js';
// import { BuyedBooksStore, BuyedBooksState, BuyedBooksUIState } from './buyed-books.store';

@Injectable({ providedIn: 'root' })
export class BuyedBooksQuery extends QueryEntity<BuyedBooksState> {
  // override ui!: EntityUIQuery<BuyedBooksUIState>;
  constructor(protected override store: BuyedBooksStore) {
    super(store);
    // this.createUIQuery();
  }
}
