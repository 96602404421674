import { Injectable } from '@angular/core';
import { Apollo, gql } from 'apollo-angular';
import { CurrentUserFacade } from '@awread/core/users';
import { map, tap } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class LibraryBooksApi {
  constructor(
    private apollo: Apollo,
    private currentUserFacade: CurrentUserFacade,
  ) { }

  getAllLibraryBooks(userId: any) {
    return this.apollo
      .query<any>({
        query: gql`
          query AllLibraryBooks($userId: UUID!) {
            libraryBooks(condition: { userId: $userId }, orderBy: CREATED_AT_DESC) {
              nodes {
                buyAt
                bookId
                bookmarkAt
                createdAt
                book {
                  title
                  cover
                  bookNid
                  bookId
                  chapters(first: 2, orderBy: POSITION_DESC) {
                    nodes {
                      chapterId
                      chapterNid
                      position
                      createdAt
                    }
                  }
                  user {
                    name
                  }
                }
              }
            }
          }
        `,
        variables: {
          userId,
        },
        fetchPolicy: 'network-only',
      })
      .pipe(map((result: any) => result?.['data']?.['libraryBooks']?.['nodes']));
  }

  getAllbuyedBooks(userId: any) {
    return this.apollo
      .query<any>({
        query: gql`
          query AllbuyedBooks($userId: UUID!) {
            libraryBooks(condition: { userId: $userId }, orderBy: CREATED_AT_DESC, filter: { buyAt: { isNull: false } }) {
              nodes {
                buyAt
                bookId
                bookmarkAt
                createdAt
                book {
                  title
                  cover
                  bookNid
                  bookId
                  chapters(first: 2, orderBy: POSITION_DESC) {
                    nodes {
                      chapterId
                      chapterNid
                      position
                      createdAt
                    }
                  }
                  user {
                    name
                  }
                }
              }
            }
          }
        `,
        variables: {
          userId,
        },
        fetchPolicy: 'network-only',
      })
      .pipe(map((result: any) => result?.['data']?.['libraryBooks']?.['nodes']));
  }

  getAllBookmarks(userId: any) {
    return this.apollo
      .query<any>({
        query: gql`
          query AllBookmarksLibraryBook($userId: UUID!) {
            libraryBooks(condition: { userId: $userId }, orderBy: CREATED_AT_DESC, filter: { bookmarkAt: { isNull: false } }) {
              nodes {
                buyAt
                bookId
                bookmarkAt
                createdAt
                book {
                  title
                  cover
                  bookNid
                  bookId
                  chapters(first: 2, orderBy: POSITION_DESC) {
                    nodes {
                      chapterId
                      chapterNid
                      position
                      createdAt
                    }
                  }
                  user {
                    name
                  }
                }
              }
            }
          }
        `,
        variables: {
          userId,
        },
        fetchPolicy: 'network-only',
      })
      .pipe(map((result: any) => result?.['data']?.['libraryBooks']?.['nodes']));
  }

  createBookmark(bookId: any) {
    return this.apollo
      .mutate<any>({
        mutation: gql`
          mutation createBookmarkLibraryBook($bookId: UUID!, $userId: UUID!, $bookmarkAt: Datetime) {
            createLibraryBook(input: { libraryBook: { bookId: $bookId, userId: $userId, bookmarkAt: $bookmarkAt } }) {
              libraryBook {
                buyAt
                createdAt
                bookId
                bookmarkAt
                
              }
            }
          }
        `,
        variables: { bookId, userId: this.currentUserFacade.getUserId(), bookmarkAt: new Date(Date.now()) },
      })
      .pipe(map((res) => res?.['data']?.['createLibraryBook']?.['libraryBook']));
  }
  updateBookmark(bookId: any) {
    return this.apollo
      .mutate<any>({
        mutation: gql`
          mutation updateBookmarkLibraryBook($bookId: UUID!, $userId: UUID!, $bookmarkAt: Datetime) {
            updateLibraryBook(input: { bookId: $bookId, userId: $userId, patch: {bookmarkAt: $bookmarkAt} }) {
              libraryBook {
                buyAt
                createdAt
                bookId
                bookmarkAt
              }
            }
          }
        `,
        variables: { bookId, userId: this.currentUserFacade.getUserId(), bookmarkAt: new Date(Date.now()) },
      })
      .pipe(map((res) => res?.['data']?.['updateLibraryBook']?.['libraryBook']));
  }

  deleteBookmark(bookId: any) {
    return this.apollo
      .mutate<any>({
        mutation: gql`
          mutation updateBookmarkLibraryBook($bookId: UUID!, $userId: UUID!, $bookmarkAt: Datetime) {
            updateLibraryBook(input: { bookId: $bookId, userId: $userId, patch: {bookmarkAt: $bookmarkAt} }) {
              libraryBook {
                buyAt
                createdAt
                bookId
                bookmarkAt
              }
            }
          }
        `,
        variables: { bookId, userId: this.currentUserFacade.getUserId(), bookmarkAt: null },
      })
      .pipe(map((res) => res?.['data']?.['updateLibraryBook']?.['libraryBook']));
  }

  getLibraryBookByBookId(userId: any, bookId: any) {
    return this.apollo
      .query<any>({
        query: gql`
          query libraryBookByBookId($bookId: UUID!, $userId: UUID!) {
            libraryBook(bookId: $bookId, userId: $userId) {
              bookId
              userId
              buyAt
              bookmarkAt
            }
          }
        `,
        variables: {
          userId,
          bookId,
        },
        fetchPolicy: 'network-only',
      })
      .pipe(map((result: any) => result?.['data']?.['libraryBook']));
  }
}
