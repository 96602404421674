import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig, ActiveState } from '@datorama/akita';
import { BuyedBook } from '../../models/index.js';

export enum VISIBILITY_FILTER {
  SHOW_ALL = 'SHOW_ALL',
}

export interface BuyedBooksState extends EntityState<BuyedBook>, ActiveState {
  ui: {
    filter: VISIBILITY_FILTER;
  };
}
const initialState = {
  ui: {
    filter: VISIBILITY_FILTER.SHOW_ALL,
  },
};

// export interface BuyedBookUI {}

// export interface BuyedBooksUIState extends EntityState<BuyedBookUI>, ActiveState {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'buyed-books', resettable: true, idKey: 'bookId' })
export class BuyedBooksStore extends EntityStore<BuyedBooksState> {
  // ui: EntityUIStore<BuyedBooksUIState>;
  constructor() {
    super();
    // this.createUIStore().setInitialEntityState();
  }
}
