import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig, ActiveState } from '@datorama/akita';
import { LibraryBook } from '../../models/index.js';

export enum VISIBILITY_FILTER {
  SHOW_ALL = 'SHOW_ALL',
}

export interface LibraryBooksState extends EntityState<LibraryBook>, ActiveState {
  ui: {
    filter: VISIBILITY_FILTER;
  };
}
const initialState = {
  ui: {
    filter: VISIBILITY_FILTER.SHOW_ALL,
  },
};

// export interface LibraryBookUI {}

// export interface LibraryBooksUIState extends EntityState<LibraryBookUI>, ActiveState {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'library-books', resettable: true, idKey: 'bookId' })
export class LibraryBooksStore extends EntityStore<LibraryBooksState> {
  // ui: EntityUIStore<LibraryBooksUIState>;
  constructor() {
    super();
    // this.createUIStore().setInitialEntityState();
  }
}
