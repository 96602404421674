import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig, ActiveState } from '@datorama/akita';
import { Bookmark } from '../../models/index.js';

export enum VISIBILITY_FILTER {
  SHOW_ALL = 'SHOW_ALL',
}

export interface BookmarkState extends EntityState<Bookmark>, ActiveState {
  ui: {
    filter: VISIBILITY_FILTER;
  };
}
const initialState = {
  ui: {
    filter: VISIBILITY_FILTER.SHOW_ALL,
  },
};

// export interface BookmarkUI {}

// export interface BookmarkUIState extends EntityState<BookmarkUI>, ActiveState {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'bookmark', resettable: true, idKey: 'bookId' })
export class BookmarkStore extends EntityStore<BookmarkState> {
  // ui: EntityUIStore<BookmarkUIState>;
  constructor() {
    super();
    // this.createUIStore().setInitialEntityState();
  }
}
