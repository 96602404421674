import { LibraryBooksQuery } from './../states/library-books/library-books.query.js';
import { LibraryBooksGear } from './../gears/library-books.gear.js';
import { Injectable, inject } from '@angular/core';
import { BuyedBooksQuery } from '../states/buyed-books/index.js';
import { BookmarkQuery } from '../states/bookmark/index.js';

@Injectable({ providedIn: 'root' })
export class LibraryBooksFacade {
  libraryBooksQuery = inject(LibraryBooksQuery);
  buyedBooksQuery = inject(BuyedBooksQuery);
  bookmarkQuery = inject(BookmarkQuery);
  libraryBooks$ = this.libraryBooksQuery.selectAll();
  buyedBooks$ = this.buyedBooksQuery.selectAll();
  bookmarks$ = this.bookmarkQuery.selectAll();
  constructor(private libraryBooksGear: LibraryBooksGear) { }

  getAllLibraryBooks() {
    return this.libraryBooksGear.getAllLibraryBooks();
  }

  getAllbuyedBooks() {
    return this.libraryBooksGear.getAllbuyedBooks();
  }

  getAllBookmarks() {
    return this.libraryBooksGear.getAllBookmarks();
  }

  createBookmark(bookId: any) {
    return this.libraryBooksGear.createBookmark(bookId);
  }

  updateBookmark(bookId: any) {
    return this.libraryBooksGear.updateBookmark(bookId);
  }

  deleteBookmark(bookId: any) {
    return this.libraryBooksGear.deleteBookmark(bookId);
  }

  getLibraryBookByBookId(bookId: any) {
    return this.libraryBooksGear.getLibraryBookByBookId(bookId);
  }
}
