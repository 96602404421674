import { LibraryBooksStore } from './../states/library-books/library-books.store.js';
import { Injectable } from '@angular/core';
import { LibraryBooksApi } from '../apis/index.js';
import { of, tap } from 'rxjs';
import { CurrentUserFacade } from '@awread/core/users';
import { BookmarkStore } from '../states/bookmark/index.js';
import { BuyedBooksStore } from '../states/buyed-books/index.js';

@Injectable({ providedIn: 'root' })
export class LibraryBooksGear {
  constructor(
    private libraryBooksApi: LibraryBooksApi,
    private libraryBooksStore: LibraryBooksStore,
    private currentUserFacade: CurrentUserFacade,
    private bookmarkStore: BookmarkStore,
    private buyedBooksStore: BuyedBooksStore,
  ) { }

  getAllLibraryBooks() {
    if (!this.currentUserFacade.getUserId()) return of();
    return this.libraryBooksApi.getAllLibraryBooks(this.currentUserFacade.getUserId()).pipe(tap((res) => this.libraryBooksStore.set(res)));
  }

  getAllbuyedBooks() {
    if (!this.currentUserFacade.getUserId()) return of();
    return this.libraryBooksApi.getAllbuyedBooks(this.currentUserFacade.getUserId()).pipe(tap((res) => this.buyedBooksStore.set(res)));
  }

  getAllBookmarks() {
    if (!this.currentUserFacade.getUserId()) return of();
    return this.libraryBooksApi.getAllBookmarks(this.currentUserFacade.getUserId()).pipe(tap((res) => this.bookmarkStore.set(res)));
  }

  createBookmark(bookId: any) {
    return this.libraryBooksApi.createBookmark(bookId).pipe(tap((res) => this.bookmarkStore.add(res)));
  }

  updateBookmark(bookId: any) {
    return this.libraryBooksApi.updateBookmark(bookId).pipe(tap((res) => this.bookmarkStore.add(res)));
  }

  deleteBookmark(bookId: any) {
    return this.libraryBooksApi.deleteBookmark(bookId).pipe(tap(() => this.bookmarkStore.remove(bookId)));
  }

  getLibraryBookByBookId(bookId: any) {
    return this.libraryBooksApi.getLibraryBookByBookId(this.currentUserFacade.getUserId(), bookId);
  }
}
