import { Injectable } from '@angular/core';
import { QueryConfig, QueryEntity, Order } from '@datorama/akita';
import { LibraryBooksStore, LibraryBooksState } from './library-books.store.js';
// import { LibraryBooksStore, LibraryBooksState, LibraryBooksUIState } from './library-books.store';
@QueryConfig({
  sortBy: 'updatedAt',
  sortByOrder: Order.DESC,
})
@Injectable({ providedIn: 'root' })
export class LibraryBooksQuery extends QueryEntity<LibraryBooksState> {
  // ui!: EntityUIQuery<LibraryBooksUIState>;
  constructor(protected override store: LibraryBooksStore) {
    super(store);
    // this.createUIQuery();
  }
}
